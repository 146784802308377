







import { Vue, Component } from 'vue-class-decorator'
import { GlobalModule } from '@/store'

@Component({})
export default class GlobalLoaderComponent extends Vue {
  @GlobalModule.Getter('getLoadingMenu') loading!: boolean
}
